






















































import { Component, Prop, Vue, Mixins } from "vue-property-decorator";
import VModal from "@/components/VModal.vue";
import ShippingContractsMixin from "@/mixins/http/ShippingContractsMixin";
import CarrierSelect from "@/components/Carrier/Select.vue";
import ShippingMethodSelect from "@/components/ShippingMethod/Select.vue";
import { Manufacture } from "@/models";
import { RequestData } from "@/store/types/RequestStore";
import { RegisterHttp } from "@/utils/Decorators";
import { showToast } from "@/utils/Toast";
import { ShippingContract } from "@/models";
import { ShippingContractPayload } from "@/models/ShippingContract";
import { namespace } from "vuex-class";
import { Source } from "@/models";
import { easync } from "@/utils/http";

const editRequestTag = "edit_warehouse_item_request_tag";
const updateStockRequestTag = "update_warehouse_item_stock_request_tag";

@Component({
  components: {
    VModal,
    CarrierSelect,
    ShippingMethodSelect
  }
})
export default class CreateModal extends Mixins(ShippingContractsMixin) {
  @RegisterHttp(editRequestTag) editRequest: RequestData;
  item: ShippingContractPayload = null;
  modal!: VModal;
  content = "";

  canceled!: any | null;

  mounted() {
    this.modal = this.$refs.modal as VModal;
  }

  get valid() {
    if (!this.item) {
      return false;
    }
    return (
      this.item.type && this.item.shippingMethodId && this.item.customerCode
    );
  }

  title() {
    return `Aggiorna contratto di spedizione`;
  }

  async update() {
    const [data, errors] = await easync(this.updateShippingContract({ ...this.item }, editRequestTag));
    if (errors) {
      showToast("Erorre durante la modifica", { type: "error" });
      return;
    }

    this.$emit("success", this.item);
    this.modal.hide();
    showToast("Contratto di spedizione aggiornato!");
  }

  get hasContent(): boolean {
    return this.$slots.default != null;
  }

  protected onCancel() {
    if (this.canceled) {
      this.canceled();
    }

    this.canceled = null;
    this.modal.hide();
  }

  protected onConfirm() {
    if (this.valid) {
      this.update();
    }
  }

  protected onHidden() {
    if (this.canceled) {
      this.canceled();
    }
  }

  show(item: ShippingContract) {
    this.modal.show();
    this.item = ShippingContractPayload.fromData(item);
  }
}
























































import { Component, Prop, Vue, Mixins } from "vue-property-decorator";
import VModal from "@/components/VModal.vue";
import ShippingContractsMixin from "@/mixins/http/ShippingContractsMixin";
import { RegisterHttp } from "@/utils/Decorators";
import { RequestData } from "@/store/types/RequestStore";
import { ShippingContractPayload } from "@/models/ShippingContract";
import CarrierSelect from "@/components/Carrier/Select.vue";
import ShippingMethodSelect from "@/components/ShippingMethod/Select.vue";
import { namespace } from "vuex-class";
import { Source } from "@/models";
import { ShippingContract } from "@/models";
import { easync } from "@/utils/http";
import { showToast } from "@/utils/Toast";
import { sourceStore } from "@/store/typed";
const createRequestTag = "create_request_tag";

@Component({
  components: {
    VModal,
    CarrierSelect,
    ShippingMethodSelect
  }
})
export default class CreateModal extends Mixins(ShippingContractsMixin) {
  @RegisterHttp(createRequestTag) createRequest: RequestData;
  modal!: VModal;
  content = "";

  private item: ShippingContractPayload = new ShippingContractPayload();

  canceled!: any | null;

  mounted() {
    this.modal = this.$refs.modal as VModal;
  }

  loading() {
    return this.createRequest?.loading;
  }

  get source() {
    return sourceStore.currentSource;
  }

  get valid() {
    return (
      this.item.type && this.item.customerCode && this.item.shippingMethodId
    );
  }

  title() {
    return `Nuovo contratto di spedizione del magazzino`;
  }

  get hasContent(): boolean {
    return this.$slots.default != null;
  }

  protected onCancel() {
    if (this.canceled) {
      this.canceled();
    }

    this.canceled = null;
    this.modal.hide();
    this.reset();
  }

  async create() {
    const [data, errors] = await easync(this.createShippingContract({ ...this.item }, createRequestTag));
    if (errors) {
      showToast("Erorre durante la creazione", { type: "error" });
      return;
    }

    this.$emit("success", data);
    this.modal.hide();
    this.reset();
  }

  protected onConfirm() {
    this.create();
  }

  protected onHidden() {
    if (this.canceled) {
      this.canceled();
    }
  }

  reset() {
    this.item = new ShippingContractPayload();
  }

  show() {
    this.modal.show();
  }
}

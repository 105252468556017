import { BASE_API_URL } from "@/utils/Constants";
import { Component, Vue } from "vue-property-decorator";

const BASE_SHIPPING_CONTRACT_URL = BASE_API_URL+"shipping_contracts"


@Component
export default class ShippingContractsMixin extends Vue {
  getShippingContracts(data: any, tag: string) {
    return this.$httpGetQuery(
      BASE_SHIPPING_CONTRACT_URL,
      tag,
      data
    );
  }

  getShippingContract(id: number, tag: string) {
    return this.$httpGet(
      BASE_SHIPPING_CONTRACT_URL+"/"+id,
      tag
    );
  }

  destroyShippingContract(id: string|number, tag: string) {
    return this.$httpDelete(BASE_SHIPPING_CONTRACT_URL+"/"+id, tag);
  }

  createShippingContract(data: any, tag: string) {
    return this.$httpPost(BASE_SHIPPING_CONTRACT_URL, tag, data);
  }

  updateShippingContract(data: any, tag: string) {
    return this.$httpPut(
      BASE_SHIPPING_CONTRACT_URL+"/"+data.id,
      tag,
      data
    );
  }
}

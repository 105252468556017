























































































import {  Source } from "@/models";
import CreateModal from "@/components/ShippingContract/Create.vue";
import ConfirmModal from "@/components/ConfirmModal.vue";
import EditModal from "@/components/ShippingContract/Edit.vue";
import {
  Vue,
  Component,
  Mixins,
  Watch,
  Ref,
} from "vue-property-decorator";
import { Getter, namespace } from "vuex-class";
import ShippingContractsMixin from "@/mixins/http/ShippingContractsMixin";
import { RegisterHttp } from "@/utils/Decorators";
import { RequestData } from "@/store/types/RequestStore";
import StoreSubscriber from "@/store/StoreSubscriber";
import DataTable from "@monade/vue-components/src/components/DataTable.vue";
import VPageContentSticky from "@/components/VPageContentSticky.vue";
import { ShippingContract, User } from "@/models";
import Search from "@/components/VEntrySearch.vue";
import { easync } from "@/utils/http";
import { showToast } from "@/utils/Toast";
import { sourceStore } from "@/store/typed";

const getTag = "get_shipping_contracts";
const destroyTag = "destroy_shipping_contract";

@Component({
  components: {
    EditModal,
    VPageContentSticky,
    DataTable,
    CreateModal,
    ConfirmModal,
    Search,
  },
})
export default class ShippingContractList extends Mixins(
  ShippingContractsMixin
) {
  @RegisterHttp(getTag) getRequest: RequestData;
  @RegisterHttp(destroyTag) destroyRequest: RequestData;
  @Ref() createModal: HTMLDivElement;
  @Ref() editModal: HTMLDivElement;
  @Ref() confirmModal: HTMLDivElement;

  @Getter currentUser!: User;

  items: ShippingContract[] = [];
  page = 1;
  search: string | null = null;

  columns = [
    { name: "logo", label: "" },
    { name: "carrier", label: "Corriere" },
    { name: "shipping_method", label: "Metodo di spedizione" },
    { name: "customer_code", label: "Codice cliente" },
    { name: "actions", label: "" },
  ];

  async searchItems(name: string) {
    if (name.trim().length > 0) {
      this.search = name;
      const products = await this.getShippingContracts(this.payload, getTag);
      return products;
    }
  }

  onSearchCanceled() {
    this.page = 1;
    this.search = null;
    this.loadItems();
  }

  carrierLogo(item: ShippingContract) {
    const carrier = this.carrier(item);
    return require(`@/assets/images/${carrier.code}.png`);
  }

  carrierName(item: ShippingContract) {
    return this.carrier(item)?.name;
  }

  carrier(item: ShippingContract) {
    return ShippingContract.carriers.find((o) => item.type === o.id);
  }

  onSearched(items: any) {
    this.page = 1;
    this.items = items.items;
  }

  onCreated(item: ShippingContract) {
    this.items.unshift(item);
  }

  onUpdated(item: ShippingContract) {
    const index = this.items.findIndex((o) => item.id === o.id);
    Vue.set(this.items, index, item);
  }

  onDeleted(id: string) {
    this.items = this.items.filter((o) => id !== o.id);
  }

  openCreateModal() {
    (this.createModal as any).show();
  }

  openEditModal(item: ShippingContract) {
    (this.editModal as any).show(item);
  }

  async openConfirmModal(item: ShippingContract) {
    const message = "Sei sicuro di eliminare questo contratto di spedizione?";
    const confirmed = await (this.confirmModal as any).confirm(message);
    if (confirmed) {
      this.destroyItem(item.id);
    }
  }

  @Watch("page")
  onPageChanged(value: any, oldValue: any) {
    if (value == 1 && oldValue) {
      this.items = [];
    }
    this.loadItems();
  }

  async destroyItem(id: string) {
    const [data, errors] = await easync(this.destroyShippingContract(id, destroyTag));
    if (errors) {
      showToast("Si è verificato un errore", { type: "error" });
      return;
    }

    this.onDeleted(id);
  }

  created() {
    this.subscribe();
  }

  mounted() {
    if (sourceStore.currentSource) {
      this.loadItems();
    }
  }

  nextPage() {
    this.page += 1;
  }


  subscribe() {
    StoreSubscriber.subscribe("source/setCurrentSource", this.onSourceSet);
  }

  onSourceSet(source: Source | undefined) {
    this.items = [];
    this.page = 1;
    if (source) {
      this.loadItems();
    }
  }

  get payload() {
    const data = {
      per: 20,
      page: this.page,
      filter: {
        source: sourceStore.currentSource.id,
        user: this.currentUser?.id,
        term: this.search,
      },
    };
    return data;
  }

  async loadItems() {
    const [data, errors] = await easync(this.getShippingContracts(this.payload, getTag));
    if (errors) {
      if (this.page > 1) {
        this.page -= 1;
      }
      return;
    }

    if (this.page === 1) {
      this.items = [];
    }
    this.items.push(...data);
  }

  get loading() {
    return this.getRequest?.loading;
  }

  get meta() {
    return this.getRequest?.meta;
  }
}

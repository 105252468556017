var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('edit-modal',{ref:"editModal",on:{"success":_vm.onUpdated}}),_c('create-modal',{ref:"createModal",on:{"success":_vm.onCreated}}),_c('confirm-modal',{ref:"confirmModal"}),_c('div',{staticClass:"page-content"},[_c('div',{staticClass:"d-flex"},[_c('p',{staticClass:"page-subtitle text-muted text-uppercase"},[_vm._v("Lista")]),_c('button',{staticClass:"ml-auto my-auto btn btn-outline-primary",on:{"click":_vm.openCreateModal}},[_vm._v(" Aggiungi ")])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-sm-6 col-md-4"},[_c('search',{attrs:{"load":_vm.searchItems,"placeholder":"Cerca.."},on:{"searched":_vm.onSearched,"cancel":_vm.onSearchCanceled}})],1)]),_c('data-table',{attrs:{"columns":_vm.columns,"values":_vm.items,"loading":_vm.loading},scopedSlots:_vm._u([{key:"column_logo",fn:function(ref){
var value = ref.value;
return [_c('img',{staticClass:"logo",attrs:{"src":_vm.carrierLogo(value)}})]}},{key:"column_carrier",fn:function(ref){
var value = ref.value;
return [_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.carrierName(value)))])]}},{key:"column_shipping_method",fn:function(ref){
var value = ref.value;
return [_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(value.shippingMethod.name))])]}},{key:"column_customer_code",fn:function(ref){
var value = ref.value;
return [_c('p',{staticClass:"mb-0 font-weight-bold"},[_vm._v(_vm._s(value.customerCode))])]}},{key:"no-results-label",fn:function(){return [(_vm.items && _vm.items.length == 0 && !_vm.loading)?_c('empty-view',{attrs:{"primaryText":"Non c'è nessun contratto di spedizione qui","secondaryText":"Creane uno e inizia a spedire!","action":"Crea nuovo"},on:{"action":_vm.openCreateModal}}):_vm._e()]},proxy:true},{key:"column_actions",fn:function(ref){
var value = ref.value;
return [_c('div',{staticClass:"d-flex"},[_c('fa-icon',{staticClass:"clickable text-primary ml-auto",attrs:{"icon":"edit"},on:{"click":function($event){return _vm.openEditModal(value)}}}),_c('fa-icon',{staticClass:"clickable ml-3 text-primary",attrs:{"icon":"trash"},on:{"click":function($event){return _vm.openConfirmModal(value)}}})],1)]}},{key:"loading",fn:function(){return [(_vm.loading)?_c('div',{staticClass:"page-content p-5 d-flex w-100 my-5"},[_c('fa-icon',{staticClass:"text-primary m-auto",attrs:{"icon":"circle-notch","size":"3x","spin":""}})],1):_vm._e()]},proxy:true}])}),(_vm.meta)?_c('div',{staticClass:"d-flex"},[(_vm.page < _vm.meta.totalPages)?_c('button',{staticClass:"btn btn-outline-primary mx-auto",on:{"click":function($event){return _vm.nextPage()}}},[_vm._v(" Carica altri ")]):_vm._e()]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
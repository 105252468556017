import { BASE_API_URL } from "@/utils/Constants";
import { Component, Vue } from "vue-property-decorator";
const BASE_SHIPPING_METHOD_URL = BASE_API_URL+"shipping_methods"

@Component
export default class ShippingMethodsMixin extends Vue {
  getShippingMethods(data: any, tag: string) {
    return this.$httpGetQuery(
      BASE_SHIPPING_METHOD_URL,
      tag,data
    );
  }
}
